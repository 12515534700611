import {Alert, CloseButton, Form, Row, Col, Button} from "react-bootstrap"

import { useState } from 'react';
import QuestionBrowser from "./Browser.js"
import { CookiesProvider } from "react-cookie"
import './App.scss';

function up(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function App() {
  const [ data, setData ] = useState(null)
  const [ examInfo, setExamInfo ] = useState(undefined)
  const [ hidePopup, setPopupHidden ] = useState(false)
  
  

  function search(event) {
    setData({
      subject: event.currentTarget[0].value.toLowerCase(),
      season: event.currentTarget[1].value.toLowerCase().split(" ")[0],
      year: event.currentTarget[1].value.toLowerCase().split(" ")[1]
    })
    event.preventDefault()
    return;
  }

  function loadExams() {
        
      if (examInfo == undefined) {
          console.log("- LOADING HOMEPAGE DATA FROM API")
          //fetch("https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/frontpage")
          fetch("https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/frontpage")
              .then(response => {
                  if (!response.ok) throw new Error("Network error")
                  return response.json()
              })
              .then(data => {
                  let newdata = {}

                  newdata.exams = data.exams;
                  newdata.subjects = data.subjects;
                  newdata.success = data.success;
                  newdata.seasons = []
                  if (data.seasons[0] == "syksy") data.seasons = data.seasons.reverse()
                  for (let year of data.years) {
                    for (let season of data.seasons) {
                      newdata.seasons.push(season + " " + year)
                    }
                  }

                  setExamInfo(newdata)
              }) 
              .catch((error) => {

                  setExamInfo({
                      success: false,
                      details: "Palvelimessa tapahtui virhe. Yritä myöhemmin uudelleen tai ota yhteys ylläpitoon."
                  })
              })
      }
  }
  if (examInfo === undefined) {
    loadExams()
    return <p>Ladataan...</p>
  }
  if (!examInfo.success) {
    return <p>Palvelimessa tapahtui virhe. Yritä myöhemmin uudelleen.</p>
  }

  const queryParams = new URLSearchParams(window.location.search)
  if (queryParams.get("answer") !== null && data == null) {
    let queryExam = null
    let questionNumber = null

    for (var candidate of examInfo.exams) {
      for (var candidateQuestion of Object.keys(candidate.questions)) {
        for (var candidateAnswer of candidate.questions[candidateQuestion].answers) {
          if (candidateAnswer.identifier === queryParams.get("answer")) {
            queryExam = candidate.identifier
            questionNumber = candidateQuestion
            break
          }
        }
        if (queryExam) break
      }
      if (queryExam) break
    }

    setData({
      subject: queryExam.split("_")[0],
      season: queryExam.split("_")[1],
      year: queryExam.split("_")[2],
      openQuestion: questionNumber,
      openAnswer: queryParams.get("answer")
    })

  }

  return (

    <div className="App">
      <br></br>
      <Alert hidden={hidePopup} className="PopupWindow" variant="success">
      <CloseButton onClick={(() => {
        setPopupHidden(true)
      })}></CloseButton>
        <Alert.Heading>
          <b>Vuoden 2024 ylioppilaiden tilastot on julkaistu!</b>
        </Alert.Heading>
        <Button href="https://mallivastaukset.fi/tilastot" style={{marginBottom: "2%", marginTop:"2%", minWidth: "40%"}}><b>Siirry tilastosivulle</b></Button>
      </Alert>

      <header className="App-header">
        <h2>Aiempien ylioppilaskokeiden mallivastaukset. Ilmaiseksi.</h2>
      <div className="SearchFormBackground">
        <Form onSubmit={search}>
          <Row className="MobileFlexAlign">
            <Col>
              <Form.Select className="EqualWidthChild Centered">
                {Array.from({length: examInfo.subjects.length}).map((_, idx) => {
                  return <option>{up(examInfo.subjects[idx])}</option>
                })}
              </Form.Select>
            </Col>
            <Col>
              <Form.Select className="EqualWidthChild Centered">
                {Array.from({length: examInfo.seasons.length}).map((_, idx) => {
                    return <option>{up(examInfo.seasons[idx])}</option>
                  })}
              </Form.Select>
            </Col>
            <Col>
              <Button id="searchButton" variant="success" type="submit">
                <span className="ShowOnMobile">Hae </span>🔎︎
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <p className = "SmallText">Kirjoititko Laudaturin? Helpota muiden lukutaakkaa ja jaa omat vastauksesi anonyymisti <a target="_blank" href="/submit">tästä</a>.</p>
      </header>
      <div>
        <br></br>
        <CookiesProvider>
          <QuestionBrowser props={data} exams={examInfo.exams}/>
        </CookiesProvider>
      </div>
    </div>
  );
}

export default App;
