import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup'
import AnswerView from './AnswerView';
import { useState } from 'react'
import { useCookies } from 'react-cookie';

function up(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function Browser(props) {
    const [cookies, setCookie] = useCookies(["favourites"])
    if (cookies.favourites === undefined) setCookie("favourites", "{}")
    const [answer, setAnswer] = useState({show: false, id: null, firstOpen: true})
    let exams = props.exams
    props = props.props
    if (props !== null && props.openAnswer !== undefined && answer.firstOpen == true) {
        setAnswer({show: true, id: props.openAnswer})
    }
    if (props === null && JSON.stringify(cookies.favourites) === "{}") {
        return (
            <>
                <br></br>
                <h2 className="Grayed">
                    Valitse koe ylläolevasta valikosta
                </h2>
                <h4 className="Grayed">
                      Tässä näkyisi tallentamasi vastaukset, mutta et ole tallentanut yhtään vastauksia pikavalikkoon.
                </h4>
            </>
        )
    } else if (props === null) {
        console.log(JSON.stringify(cookies.favourites))

        /*
            Not possible to "refavourite" here because we have no idea what exam the answer is from.
        */
        function updateFavourite() {
            let newfavourites = JSON.parse(JSON.stringify(cookies.favourites))
            if (newfavourites.hasOwnProperty(answer.id)) {
                delete newfavourites[answer.id]
            }
            setAnswer({show: false, id: null})
            setCookie("favourites", newfavourites)
            console.log("Updated favourite status of " + answer.id + " to " + newfavourites.hasOwnProperty(answer.id))
        }
        return (
        <div className="Questions" style={{width:"90%", alignContent:"center"}}>
            <h2>Pikavalikko</h2>
            <p>Voit lisätä vastauksia pikavalikkoosi painamalla asianmukaista nappia lukiessasi vastausta. Pikavalikkosi tallentuu, vaikka poistuisit sivustolta.</p>   
            <hr></hr>
            <AnswerView show = {answer.show} id = {answer.id} setAnswer = {setAnswer} updateFavourite = {cookies.favourites.hasOwnProperty(answer.id) ? updateFavourite : null} favourite = {cookies.favourites.hasOwnProperty(answer.id)}/>
            <ListGroup>
                {Array.from({ length: Object.keys(cookies.favourites).length}).map((_, ansid) => (
                    <ListGroup.Item key={Object.keys(cookies.favourites)[ansid]} action onClick={
                        () => {
                            console.log("Showing")
                            console.log(Object.keys(cookies.favourites)[ansid])
                            setAnswer({show: true, id: Object.keys(cookies.favourites)[ansid]})
                            window.history.replaceState(null, "", "/?answer=" + Object.keys(cookies.favourites)[ansid])
                        }
                    }>
                        <span>
                            <span style={{color:"#ffc107"}}>
                                {cookies.favourites.hasOwnProperty(Object.keys(cookies.favourites)[ansid]) && (
                                    "★ "
                                )}
                            </span>
                            {ansid + 1}: 
                            <b>
                                {" " + Object.keys(cookies.favourites)[ansid]}
                            </b> 

                        </span> 
                        <span style={{ position: "absolute", right: "5px" }}> 
                            <p>{up(cookies.favourites[Object.keys(cookies.favourites)[ansid]].split("_")[0])
                            + ", " + cookies.favourites[Object.keys(cookies.favourites)[ansid]].split("_")[1]
                            + " " + cookies.favourites[Object.keys(cookies.favourites)[ansid]].split("_")[2]}</p>
                        </span>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>)
    } else {
        let exam = undefined
        for (var examoption of exams) {
            if (examoption.identifier === props.subject + "_" + props.season + "_" + props.year) {
                exam = examoption
                break
            }
        }
        if (exam !== undefined) {
            let keys = []
            let values = []
            for (var key of Object.keys(exam.questions)) {
                keys.push(parseInt(key))
                values.push({
                    tags: [],
                    answers: [],
                    ...exam.questions[(parseInt(key)).toString()]
                })
            }
    
            function updateFavourite() {
                let newfavourites = JSON.parse(JSON.stringify(cookies.favourites))
                if (newfavourites.hasOwnProperty(answer.id)) {
                    delete newfavourites[answer.id]
                } else {
                    newfavourites[answer.id] = exam.identifier
                }
                setCookie("favourites", newfavourites)
                console.log("Updated favourite status of " + answer.id + " to " + newfavourites.hasOwnProperty(answer.id))
            }
            return (
                <div className="Questions">
                    <h1>
                        {up(props.season) + " " + props.year + ": " + up(props.subject)} - Valitse kysymys
                    </h1>
                    <AnswerView show = {answer.show} id = {answer.id} setAnswer = {setAnswer} updateFavourite = {updateFavourite} favourite = {cookies.favourites.hasOwnProperty(answer.id)}/>
                    <br></br>
                    <Accordion defaultActiveKey={parseInt(props.openQuestion)}>
                        {Array.from({ length: keys.length }).map((_, idx) => (
                            <>
                                <Accordion.Item eventKey={keys[idx]} className="Question">
                                    <Accordion.Header>
                                        <div>
                                            <span>
                                                <b>Kysymys {keys[idx] + " "} </b>
                                                <span>
                                                    {values[idx].name + " "}
                                                </span>
                                            </span>
                                            <span className="MobileResponsive">
                                                <span>
                                                    {Array.from({ length: values[idx].tags.length }).map((_, tag) => (
                                                        <>
                                                            <span className={(values[idx].tags[tag] === "Aineistotehtävä") ? "HideOnMobile Badge" : "Badge"}>
                                                                {values[idx].tags[tag]}
                                                            </span>
                                                            {" "}
                                                        </>))
                                                    }
                                                    <span className="Badge" style={{ background: (values[idx].answers.length > 0 ? "orange" : "red") }}>
                                                        {values[idx].answers.length + " " + (values[idx].answers.length === 1 ? "vastaus" : "vastausta")}
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ListGroup>
                                            {Array.from({ length: values[idx].answers.length }).map((_, ansid) => (
                                                <ListGroup.Item key={values[idx].answers[ansid].identifier} action onClick={
                                                    () => {
                                                        console.log("Showing")
                                                        console.log(values[idx].answers[ansid].identifier)
                                                        setAnswer({show: true, id: values[idx].answers[ansid].identifier})
                                                        window.history.replaceState(null, "", "/?answer=" + values[idx].answers[ansid].identifier)
                                                        console.log(answer);
                                                    }
                                                }>
                                                    <span>
                                                        <span style={{color:"#ffc107"}}>
                                                            {cookies.favourites.hasOwnProperty(values[idx].answers[ansid].identifier) && (
                                                                "★ "
                                                            )}
                                                        </span>
                                                        {ansid + 1}. Vastaus: 
                                                        <b>
                                                            {" " + values[idx].answers[ansid].identifier}
                                                        </b> 

                                                    </span> 
                                                    <span className="MobileResponsive"> 
                                                        <span className="Badge">
                                                            {values[idx].answers[ansid].length +" merkkiä"} 
                                                        </span>
                                                        <span> </span>
                                                        <span className="Badge">
                                                            {values[idx].answers[ansid].points +"/" + values[idx].tags[values[idx].tags.length-1]} 
                                                        </span>
                                                    </span>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                        {(values[idx].answers.length === 0 && <p>Ei vastauksia tähän kysymykseen.</p>)}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <br></br>
                            </>
                        ))}
                    </Accordion>
                </div>
            )
        } else {
            return (
                <h2 className="Grayed">Tätä koetta ei ole vielä järjestelmässämme</h2>
            )
        }
    }
}

export default Browser;