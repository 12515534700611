import { useState } from 'react'
import './App.scss';
import { Alert, Image, FloatingLabel, Form, Button, Accordion } from "react-bootstrap"
import ExamUploadBox from './ExamUploadBox';
import { isMobile } from "react-device-detect"

export default function UploadPage() {

    const [stage, setStage] = useState(0)
    const [submitDisabled, setSubmitDisabled] = useState(true)
    console.log("platform: " + process.env.WEB_PLATFORM)
    //const [stage, setStage] = useState(7)
    if (isMobile) {
        return (
            <div className='Centered'>
                <div style={{width: "90%", margin: "auto"}}>
                    <Image className="Centered" src="laptop.jpg"/>
                    <hr></hr>
                    <h3 className='Centered'>Valitettavasti vastaukset voi jakaa vain tietokoneella.</h3>
                    <br></br>
                    <h4 className='Centered'>Mene siis läppärillä tai pöytäkoneella osoitteeseen mallivastaukset.fi ja aloita jakaminen vaikka heti!</h4>
                    <Button href="https://mallivastaukset.fi">Takaisin etusivulle</Button>
                </div>
            </div>
        )
    } else return (
        <div>
            <header className="App-header">
                <h3>Aiempien ylioppilaskokeiden mallivastaukset. Ilmaiseksi.</h3>
                <h4>Jaa omat vastauksesi muille turvallisesti ja anonyymisti.</h4>
            </header>
            <br></br>
            {stage === 0 && 
            <div className="Centered" style={{flexDirection: "column", marginBottom: "10vh", display:"flex"}}>
                <div className="FaqBox">
                    <h3>Usein kysyttyjä kysymyksiä vastausten jakamisesta</h3>
                    <hr></hr>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Saako YO-kokeiden vastauksia jakaa?</Accordion.Header>
                            <Accordion.Body><p style={{whiteSpace: "pre-line"}}>Kyllä saa. Koesuorituksen yläreunassa on merkintä "Salassapidettävä", jossa kerrotaan, että kokeen vastauksia ei saisi jakaa. <b>Tämä salassapitovaatimus ei koske sitä henkilöä, joka itse kirjoitti kokeen vastaukset.</b> 
                            {"\n\n"}
                            Varoituksessa mainitun <a href="https://www.finlex.fi/fi/laki/ajantasa/1999/19990621#L7P26">julkisuuslain 26 §:n 2. kohdan</a> mukaisesti "Viranomainen voi antaa salassa pidettävästä viranomaisen asiakirjasta tiedon jos se, jonka etujen suojaamiseksi salassapitovelvollisuus on säädetty, antaa siihen suostumuksensa". Myös saman lain 12 § tekee selväksi, että "Jokaisella on oikeus saada tieto hänestä itsestään viranomaisen asiakirjaan sisältyvistä tiedoista".
                            {"\n\n"}
                            Salassapitolauseke on tarkoitettu alustavaa arviointia tekevälle opettajalle, sensorille sekä muille henkilöille, jotka näkevät vastauksesi. <b>Vastausten luojalla itsellään on täysi oikeus jakaa omat vastauksensa haluamilleen tahoille.</b>
                            </p></Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Jaetaanko myös sensorin antamat kommentit?</Accordion.Header>
                            <Accordion.Body><p>Ei jaeta. Myöskään alustavia arviointeja tai kommentteja ei jaeta.</p></Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Voinko muokata vastauksiani ennen kuin jaan ne?</Accordion.Header>
                            <Accordion.Body><p style={{whiteSpace: "pre-line"}}>Et valitettavasti voi. Haluamme, että sivuston käyttäjät pääsevät katselemaan mahdollisimman totuudenmukaisia yo-vastauksia, ja tämä tarkoittaa, että vastauksissa on oltava myös virheitä ja epäkohtia. Muistathan, että jakamasi vastaukset ovat anonyymejä.
                            {"\n\n"}
                            Huom: Jos haluat muokata vastauksiasi koska ne sisältävät sinua tai muita ihmisiä koskevia yksityisiä tietoja, pyydämme, että jätät kyseisen kokeen vastaukset jakamatta.
                            </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Voinko poistaa vastaukseni myöhemmin?</Accordion.Header>
                            <Accordion.Body><p>Voit myöhemmin poistaa vastauksesi. Näin tehdäksesi sinun tulee ottaa yhteyttä palvelun ylläpitoon. Vastausten poistamiseen tarvitsemme YO-kokeidesi jakolinkin.</p></Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Mitä henkilötietojani palvelu säilyttää?</Accordion.Header>
                            <Accordion.Body><p>Palvelu ei säilytä mitään henkilötietoja paitsi salatun tunnisteen, jonka avulla varmistamme, että samoja vastauksia ei jaeta useampaa kertaa. Tunnisteesta ei pysty selvittämään identiteettiä, eikä sitä jaeta palvelussa.</p></Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Voinko valita minkä kokeiden vastaukset haluan jakaa?</Accordion.Header>
                            <Accordion.Body><p>Kyllä. Voit valita jaettavat kokeet tunnistauduttuasi.</p></Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Minkä kokeiden vastauksia voi jakaa palveluun?</Accordion.Header>
                            <Accordion.Body><p>Tällä hetkellä palveluun voi jakaa vastauksia seuraavista aineista: Historia, yhteiskuntaoppi, psykologia, filosofia, biologia, elämänkatsomustieto. Pyrimme lisäämään pian evankelis-luterilaisen uskonnon.</p></Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>
            </div>
                }
            <div className="UploadPage">
                <div className="UploadBox">
                    {uploadBox(stage, setStage, submitDisabled, setSubmitDisabled)}
                </div>
            </div>
            
        </div>
    )
}

let errorMessage = ""
let serverData = {}

function uploadBox(stage, setStage, submitDisabled, setSubmitDisabled) {
    function formSubmit(argument) {
        console.log("formSubmit")
        var url = "https://mallivastaukset-cdn-sxxnp.ondigitalocean.app/newverify";
        //var url = "http://localhost:8080/newverify";
        var request = new XMLHttpRequest();
        request.open('POST', url, true);
        request.onload = function() { // request successful
        // we can use server response to our request now

            if (JSON.parse(request.responseText) !== undefined && JSON.parse(request.responseText).success == true) {
                console.log("a")
                serverData =JSON.parse(request.responseText);
                console.log("b")
                setStage(7)
            } else {
                errorMessage = (JSON.parse(request.responseText) !== undefined ? JSON.parse(request.responseText) : request.responseText)
                setStage(-2)
            }
            
        };
      
        request.onerror = function() {
            errorMessage = request.responseText
            setStage(-2)
        };
        let formData = new FormData()
        formData.append("url", argument)
        request.send(formData);
        setStage(6)
      }

    console.log(stage)
    if (stage === -2) {
        return (
            <>
                <Alert variant="danger">
                    <p style={{whiteSpace: "pre-line"}}>Palvelimen virhe. {(typeof(errorMessage) !== "object" ? errorMessage : errorMessage.error)}</p>
                </Alert>
                <button onClick={() => window.location.reload(true)}>Takaisin</button>
            </>

        )
    }

    if (stage === -1) {
        return (
            <>
                <p>Vastausten jakaminen vaatii tietokoneen sekä pääsyn Oma Opintopolku-palveluun. Tällä hetkellä vain Laudaturin arvosanan saaneiden suoritusten vastauksia voi jakaa.</p>
                <button onClick={(() => setStage(0))}>Aloita</button>
            </>
        )
    }
    if (stage === 0) {
        return (
            <>
                <br></br>
                <h4>Vaihe 1: Suorituksen todistaminen</h4>
                <p>Vaadimme suorituksen todistamisen kaikilta vastauksen jättäviltä. Emme säilytä henkilötietojasi tai jaa niitä muille.</p>
                <p>Todistaminen tapahtuu Oma Opintopolku-palvelusta saatavilla olevan YO-todistuslinkin kautta. Avaa Oma Opintopolku <a href="https://opintopolku.fi/oma-opintopolku/" target="_blank"> klikkaamalla tästä</a> (avautuu uuteen välilehteen). </p>
                <p>Paina 'Siirry opintosuorituksiin (vaatii tunnistautumisen)' ja tunnistaudu.</p>
                <hr></hr>
                <Image src="https://i.imgur.com/vBc6xyj.png" fluid className="Image" alt=""></Image>
                <br></br>
                <button onClick={(() => setStage(1))}>Seuraava vaihe</button>
            </>
        )
    }
    if (stage === 1) {
        return (
            <>
                <h4>Vaihe 2: Suoritusten jakolinkin luominen</h4>
                <p>Tunnistauduttuasi sinulla pitäisi nyt olla alla olevan tapainen näkymä. Paina nappia 'Jaa suoritustietoja'.</p>
                <hr></hr>
                <Image src="https://i.imgur.com/lzOBAjq.png" fluid className="Image" alt=""></Image>
                <br></br>
                <button onClick={(() => setStage(2))}>Seuraava vaihe</button>
            </>
        )
    }
    if (stage === 2) {
        return (
            <>
                <h4>Vaihe 3: Suoritusten jakolinkin luominen</h4>
                <p>Valitse vasemmasta valikosta 'Ylioppilastutkinto'. Muita vaihtoehtoja ei tarvitse valita. Paina sitten 'Jaa valitsemasi opinnot'</p>
                <hr></hr>
                <Image src="https://i.imgur.com/ykCvhXx.png" fluid className="Image" alt=""></Image>
                <br></br>
                <button onClick={(() => setStage(3))}>Seuraava vaihe</button>
            </>
        )
    }
    if (stage === 3) {
        return (
            <>
                <h4>Vaihe 4: Jakolinkin lähetys</h4>
                <p>Paina kopioi linkki. Liitä kopioitu linkki alla olevaan kenttään. Voit liittää leikepöydältäsi käyttäen kirjainyhdistelmää CTRL + V kun vastauskenttä on valittuna.</p>
                <hr></hr>
                <br></br>
                <Image src="https://i.imgur.com/lvqUZK0.png" fluid className="Image" alt=""></Image>
                <br></br>
                <FloatingLabel
                    controlId="urlUpload"
                    label="Liitä opintopolku.fi -linkki tähän"
                    className="mb-3"
                >
                    <Form.Control onChange={(event) => {
                        let regex = /https:\/\/opintopolku\.fi\/koski\/opinnot\/([A-Za-z0-9]+)/i
                        setSubmitDisabled(!event.target.value.match(regex))
                        console.log(event.target.value.match(regex))
                    }}/>
                </FloatingLabel>
                <Button variant="secondary" disabled={submitDisabled} onClick={ () => {
                    if (document.getElementById("urlUpload").value !== "") formSubmit(document.getElementById("urlUpload").value)
                }}>Lähetä</Button>
                
            </>
        )
    }
    if (stage === 6) {
        return (
            <>
                <h4>Ladataan...</h4>
                <p>YO-todistustasi tarkistetaan. Tämän ei pitäisi kestää paria sekuntia kauempaa.</p>
                <br></br>
            </>
        )
    }
    if (stage === 7) {
        return (
            <>
                <h4>Vaihe 7: Vastausten lisääminen</h4>
                <p>YO-todistuksesi tarkastus onnistui. Voit nyt ladata vastauksesi palveluun. Onnittelut menestyksestä kirjoituksissa!</p>
                <p>Voit ladata vastauksia alla oleviin aineisiin. Vastausten lataaminen tapahtuu painamalla Opintopolun 'Näytä koesuoritus' painiketta. Kun koe on auki selaimessa, paina näppäinyhdistelmää CTRL + S ja tallenna html-tiedosto laitteellesi. Lähetä kyseinen html-tiedosto tämän sivun latauskenttiin alla.</p>
                <p>Jos et halua antaa jonkun kokeen vastauksia, voit painaa vasenta nappia ja olla näin lataamatta kyseistä koetta.</p>
                <p style={{color: "red"}}>Älä muokkaa tiedostoja millään tavalla. Tarkastamme kaikkien vastausten alkuperäisyyden. Muokatun vastauksen lähetys johtaa automaattiseen porttikieltoon.</p>
                <hr></hr>
                <br></br>
                <ExamUploadBox serverData={serverData}/>
                <Image src="https://i.imgur.com/ExiBK1L.png" fluid className="Image" alt=""></Image>
                <br></br>
            </>
        )
    }
}